.user-ratings-container {
    max-width: 800px;
    margin: 0;
}

.user-ratings-list {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.user-ratings-item {
    display: flex;
    background-color: #3A3A3A;
    text-decoration: none;
    color: inherit;
    padding: 12px 0;
}

.user-ratings-item:hover {
    box-shadow: none;
    transform: none;
}

.user-ratings-divider {
    border: none;
    height: 1px;
    background-color: var(--color-background-dark);
    margin: 0;
}

.user-ratings-poster {
    width: 92px;
    height: 138px;
    flex-shrink: 0;
}

.user-ratings-poster-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.user-ratings-poster-placeholder {
    width: 100%;
    height: 100%;
    background-color: #2A2A2A;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    font-size: 0.8rem;
}

.user-ratings-title-link {
    text-decoration: none;
    color: unset;
}

.user-ratings-content {
    padding: 0 16px;
    flex-grow: 1;
    max-width: calc(100% - 92px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.user-ratings-date {
    color: #888;
    font-size: 0.9rem;
    margin-bottom: 8px;
}

.user-ratings-title {
    color: #fff;
    font-size: 1.2rem;
    margin: 0 0 8px 0;
    font-weight: 600;
}

.user-ratings-title:hover {
    text-decoration: underline;
}

.user-ratings-metadata {
    color: #888;
    font-size: 0.9rem;
    margin-bottom: 12px;
}

.user-ratings-separator {
    margin: 0 8px;
}

.user-ratings-score {
    display: flex;
    align-items: center;
    gap: 4px;
}

.user-ratings-score-icon {
    color: var(--color-brand);
    font-size: 16px;
}

.user-ratings-score-value {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}

.user-ratings-score-max {
    display: none;
}

.user-ratings-pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    flex-wrap: wrap;
}

.user-ratings-pagination-button {
    background-color: #f8f8f8;
    border: 1px solid #e0e0e0;
    color: #333;
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.user-ratings-pagination-button:hover {
    background-color: #e0e0e0;
}

.user-ratings-pagination-button.active {
    background-color: var(--color-brand);
    color: white;
}

.user-ratings-loading,
.user-ratings-error {
    text-align: center;
    margin-top: 2rem;
    font-size: 1.2rem;
}

.user-ratings-no-ratings {
    font-style: italic;
    color: #666;
    text-align: center;
    padding: 2rem;
}

.user-ratings-overview {
    font-size: 1rem;
    min-height: 20px;
    margin-bottom: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

@media (max-width: 600px) {
    .user-ratings-container {
        padding: 12px;
    }

    .user-ratings-item {
        padding: 8px 0;
    }

    .user-ratings-poster {
        width: 60px;
        height: 90px;
    }

    .user-ratings-content {
        padding: 0 12px;
        max-width: calc(100% - 60px);
        width: 100%;
    }

    .user-ratings-title {
        font-size: 1rem;
    }

    .user-ratings-metadata {
        font-size: 0.8rem;
    }

    .user-ratings-separator {
        margin: 0 4px;
    }
}