.mobile-watched-titles-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 10px;
}

.mobile-watched-titles-item {
  width: 100%;
  aspect-ratio: 2 / 3;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mobile-watched-titles-poster {
  width: 100%;
  height: 100%;
}

.mobile-watched-titles-poster-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mobile-watched-titles-poster-placeholder {
  width: 100%;
  height: 100%;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: #666;
  text-align: center;
}