body {
  font-family: 'SUSE', sans-serif;
  margin: 0;
  padding: 0;
  /* background-color: #c6d3d83b; */
  background-color: #202020e1;
  /* color: #2B2628; */
  color: #dbdbdb;
  --color-white: #dbdbdb;
  --color-brand: #BF2722;
  --color-brand-opacity-80: #bf2722c3;
  --color-brand-opacity-60: #bf272292;
  --color-brand-opacity-40: #bf272262;
  --color-brand-opacity-20: #bf27223e;
  --color-background-dark: #2B2628;
  --color-border-grey: grey;
  --color-subtitle-grey: rgb(196, 196, 196);
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

.container {
  margin: 0 auto;
  padding: 0;
}

.hero {
  height: calc(100vh - 60px); /* Adjust 60px to your header height */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  color: white;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('https://images.pexels.com/photos/162389/lost-places-old-decay-ruin-162389.jpeg?auto=compress&cs=tinysrgb&dpr=1');
  background-size: cover;
  background-position: center;
  opacity: 0.8;
  filter: blur(4px);
  z-index: -2;
}

.hero::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.145); /* Add a semi-transparent overlay */
  z-index: -1;
}

.hero-content {
  text-align: center;
  max-width: 95%;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  position: relative;
  z-index: 1;
}

.hero h1 {
  font-weight: 400;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.recently-updated {
  padding: 3rem 2rem; /* Increased padding */
  max-width: 1200px; /* Added max-width for larger screens */
  margin: 0 auto; /* Center the content */
}

.recently-updated h2 {
  margin-bottom: 1.5rem; /* Increased margin */
}

.brand-red {
  color: #BF2722;;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .hero {
    height: auto;
    min-height: calc(100vh - 60px);
  }

  .hero-content {
    padding: 2rem 1rem;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .recently-updated {
    padding: 2rem 1rem; /* Adjusted padding for mobile */
  }

  main {
    padding-bottom: 60px; /* Adjust this value based on the height of your mobile nav */
  }

  .main-footer {
    display: none;
  }
}

.release-calendar-section h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.release-calendar-section,
.trending-horror-section {
  max-width: 2000px;
  margin: 0 auto;
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.trending-horror-section {
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 5vh;
}

.rbc-toolbar {
  flex-direction: row-reverse;
}

.rbc-toolbar .rbc-toolbar-label {
  text-align: left;
  font-weight: 400;
}

.rbc-btn-group button {
  cursor: pointer;
}

.user-profile-container {
  max-width: 100%;
  margin: 2rem auto;
  padding: 2rem 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-profile-title {
  font-size: 2rem;
  color: #2B2628;
  margin-bottom: 1.5rem;
  text-align: center;
}

.user-profile-info {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.user-profile-name {
  font-size: 1.5rem;
  color: #BF2722;
  margin-bottom: 1rem;
}

.user-profile-ratings-container,
.user-profile-horror-profile {
  background-color: #1a1a1a;
  padding: 1rem;
  border-radius: 4px;
}

.user-profile-ratings-container h3,
.user-profile-horror-profile h3 {
  font-size: 1.2rem;
  color: #2B2628;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.user-profile-signout-button.mobile-only {
  display: none;
}

@media (max-width: 768px) {
  .user-profile-container {
    padding: 1rem;
    margin: 1rem;
  }

  .user-profile-title {
    font-size: 1.5rem;
  }

  .user-profile-name {
    font-size: 1.2rem;
  }

  .user-profile-ratings-grid {
    grid-template-columns: 1fr; /* Change to single column for mobile */
  }

  .user-profile-rating-box {
    max-width: 100%; /* Ensure full width on mobile */
  }

  .user-profile-rating-content {
    height: auto; /* Allow content to determine height */
    flex-direction: row; /* Keep poster and info side by side */
    align-items: center; /* Center items vertically */
  }

  .user-profile-movie-poster-container {
    width: 60px; /* Reduce poster size for mobile */
    height: 90px;
    margin-right: 1rem; /* Add some space between poster and info */
  }

  .user-profile-movie-info {
    flex: 1; /* Allow info to take remaining space */
  }

  .user-profile-movie-title {
    font-size: 0.9rem; /* Slightly reduce font size */
    color: #1a1a1a;
  }

  .user-profile-movie-rating,
  .user-profile-movie-year {
    font-size: 0.8rem; /* Reduce font size for mobile */
  }

  .user-profile-signout-button.mobile-only {
    display: block;
    background-color: #BF2722;
    border: none;
    width: 100%;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    text-align: center;
    margin-top: 1rem;
    font-size: 1rem;
  }
}

.user-profile-loading,
.user-profile-error {
  text-align: center;
  margin-top: 2rem;
  font-size: 1.2rem;
}

.user-profile-ratings-list {
  list-style-type: none;
  padding: 0;
}

.user-profile-rating-item {
  margin-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1rem;
}

.user-profile-rating-item:last-child {
  border-bottom: none;
}

.user-profile-movie-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.user-profile-movie-poster {
  width: 46px;
  height: 69px;
  object-fit: cover;
  margin-right: 1rem;
}

.user-profile-movie-info {
  display: flex;
  flex-direction: column;
}

.user-profile-movie-title {
  font-weight: bold;
}

.user-profile-movie-year {
  color: #666;
  font-size: 0.9rem;
}

.user-profile-movie-rating {
  color: #BF2722;
  font-weight: bold;
}

.user-profile-movie-date {
  color: #666;
  font-size: 0.9rem;
}

.user-profile-no-ratings {
  font-style: italic;
  color: #666;
}

@media (max-width: 768px) {
  .user-profile-movie-poster {
    width: 40px;
    height: 60px;
  }

  .user-profile-movie-info {
    font-size: 0.9rem;
  }
}

.user-profile-note {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  padding: 1rem;
  margin-top: 1rem;
  color: #856404;
}

.user-profile-ratings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.user-profile-rating-box {
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  transition: box-shadow 0.3s ease;
}

.user-profile-rating-box:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-profile-rating-content {
  display: flex;
  height: 138px;
}

.user-profile-movie-poster-container {
  width: 92px;
  height: 138px;
  flex-shrink: 0;
}

.user-profile-movie-poster {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-profile-movie-poster-placeholder {
  width: 100%;
  height: 100%;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: #666;
}

.user-profile-movie-info {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.user-profile-movie-title {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.user-profile-movie-rating {
  color: #BF2722;
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

.user-profile-movie-year {
  color: #666;
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  .user-profile-ratings-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

.user-profile-container {
  padding-top: 20px;
}

.user-profile-ratings-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start;
}

.user-profile-rating-box {
  flex: 0 0 calc(33.333% - 1rem);
  max-width: calc(33.333% - 1rem);
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.user-profile-rating-box:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.user-profile-movie-poster-container {
  width: 92px;
  height: 138px;
  flex-shrink: 0;
}

.user-profile-movie-poster {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-profile-movie-poster-placeholder {
  width: 100%;
  height: 100%;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: #666;
}

.user-profile-movie-info {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.user-profile-movie-title {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.user-profile-movie-rating {
  color: #BF2722;
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

.user-profile-movie-year {
  color: #666;
  font-size: 0.8rem;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

.container {
  margin: 0 auto;
  padding: 0;
}

.hero {
  height: calc(100vh - 60px); /* Adjust 60px to your header height */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  color: white;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('https://images.pexels.com/photos/162389/lost-places-old-decay-ruin-162389.jpeg?auto=compress&cs=tinysrgb&dpr=1');
  background-size: cover;
  background-position: center;
  opacity: 0.8;
  filter: blur(4px);
  z-index: -2;
}

.hero::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.145); /* Add a semi-transparent overlay */
  z-index: -1;
}

.hero-content {
  text-align: center;
  max-width: 95%;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  position: relative;
  z-index: 1;
}

.hero h1 {
  font-weight: 400;
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.recently-updated {
  padding: 3rem 2rem; /* Increased padding */
  max-width: 1200px; /* Added max-width for larger screens */
  margin: 0 auto; /* Center the content */
}

.recently-updated h2 {
  margin-bottom: 1.5rem; /* Increased margin */
}

.brand-red {
  color: #BF2722;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .hero {
    height: auto;
    min-height: calc(100vh - 60px);
  }

  .hero-content {
    padding: 2rem 1rem;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .recently-updated {
    padding: 2rem 1rem; /* Adjusted padding for mobile */
  }

  main {
    padding-bottom: 60px; /* Adjust this value based on the height of your mobile nav */
  }

  .main-footer {
    display: none;
  }
}

.release-calendar-section h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.release-calendar-section,
.trending-horror-section {
  max-width: 2000px;
  margin: 0 auto;
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.trending-horror-section {
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 5vh;
}

.rbc-toolbar {
  flex-direction: row-reverse;
}

.rbc-toolbar .rbc-toolbar-label {
  text-align: left;
  font-weight: 400;
}

.rbc-btn-group button {
  cursor: pointer;
}

.user-profile-container {
  max-width: 100%;
  margin: 2rem auto;
  padding: 2rem 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-profile-title {
  font-size: 2rem;
  color: #2B2628;
  margin-bottom: 1.5rem;
  text-align: center;
}

.user-profile-info {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.user-profile-name {
  font-size: 1.5rem;
  color: #BF2722;
  margin-bottom: 1rem;
}

.user-profile-ratings-container,
.user-profile-horror-profile {
  background-color: #1a1a1a;
  padding: 1rem;
  border-radius: 4px;
}

.user-profile-ratings-container h3,
.user-profile-horror-profile h3 {
  font-size: 1.2rem;
  color: #2B2628;
  margin-bottom: 0.5rem;
}

.user-profile-signout-button.mobile-only {
  display: none;
}

@media (max-width: 768px) {
  .user-profile-container {
    padding: 1rem;
    margin: 1rem;
  }

  .user-profile-title {
    font-size: 1.5rem;
  }

  .user-profile-name {
    font-size: 1.2rem;
  }

  .user-profile-ratings-grid {
    grid-template-columns: 1fr; /* Change to single column for mobile */
  }

  .user-profile-rating-box {
    max-width: 100%; /* Ensure full width on mobile */
  }

  .user-profile-rating-content {
    height: auto; /* Allow content to determine height */
    flex-direction: row; /* Keep poster and info side by side */
    align-items: center; /* Center items vertically */
  }

  .user-profile-movie-poster-container {
    width: 60px; /* Reduce poster size for mobile */
    height: 90px;
    margin-right: 1rem; /* Add some space between poster and info */
  }

  .user-profile-movie-info {
    flex: 1; /* Allow info to take remaining space */
  }

  .user-profile-movie-title {
    font-size: 0.9rem; /* Slightly reduce font size */
  }

  .user-profile-movie-rating,
  .user-profile-movie-year {
    font-size: 0.8rem; /* Reduce font size for mobile */
  }

  .user-profile-signout-button.mobile-only {
    display: block;
    background-color: #BF2722;
    border: none;
    width: 100%;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    text-align: center;
    margin-top: 1rem;
    font-size: 1rem;
  }
}

.user-profile-loading,
.user-profile-error {
  text-align: center;
  margin-top: 2rem;
  font-size: 1.2rem;
}

.user-profile-ratings-list {
  list-style-type: none;
  padding: 0;
}

.user-profile-rating-item {
  margin-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1rem;
}

.user-profile-rating-item:last-child {
  border-bottom: none;
}

.user-profile-movie-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.user-profile-movie-poster {
  width: 46px;
  height: 69px;
  object-fit: cover;
  margin-right: 1rem;
}

.user-profile-movie-info {
  display: flex;
  flex-direction: column;
}

.user-profile-movie-title {
  font-weight: bold;
}

.user-profile-movie-year {
  color: #666;
  font-size: 0.9rem;
}

.user-profile-movie-rating {
  color: #BF2722;
  font-weight: bold;
}

.user-profile-movie-date {
  color: #666;
  font-size: 0.9rem;
}

.user-profile-no-ratings {
  font-style: italic;
  color: #666;
}

@media (max-width: 768px) {
  .user-profile-movie-poster {
    width: 40px;
    height: 60px;
  }

  .user-profile-movie-info {
    font-size: 0.9rem;
  }
}

.user-profile-note {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  padding: 1rem;
  margin-top: 1rem;
  color: #856404;
}

.user-profile-ratings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.user-profile-rating-box {
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  transition: box-shadow 0.3s ease;
}

.user-profile-rating-box:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-profile-movie-poster-container {
  width: 92px;
  height: 138px;
  flex-shrink: 0;
}

.user-profile-movie-poster {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-profile-movie-poster-placeholder {
  width: 100%;
  height: 100%;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: #666;
}

.user-profile-movie-info {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.user-profile-movie-title {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.user-profile-movie-rating {
  color: #BF2722;
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

.user-profile-movie-year {
  color: #666;
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  .user-profile-ratings-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

.user-profile-container {
  padding-top: 0px;
}

.user-profile-ratings-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start;
}

.user-profile-rating-box {
  flex: 0 0 calc(33.333% - 1rem);
  max-width: calc(33.333% - 1rem);
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.user-profile-rating-box:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.user-profile-movie-poster-container {
  width: 92px;
  height: 138px;
  flex-shrink: 0;
}

.user-profile-movie-poster {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-profile-movie-poster-placeholder {
  width: 100%;
  height: 100%;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: #666;
}

.user-profile-movie-info {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.user-profile-movie-title {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.user-profile-movie-rating {
  color: #BF2722;
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

.user-profile-movie-year {
  color: #666;
  font-size: 0.8rem;
}

.user-profile-pagination {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.user-profile-pagination-button {
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  color: #333;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-profile-pagination-button:hover {
  background-color: #e0e0e0;
}

.user-profile-pagination-button.active {
  background-color: #BF2722;
  color: white;
}

@media (max-width: 1200px) {
  .user-profile-rating-box {
    flex: 0 0 calc(50% - 1rem);
    max-width: calc(50% - 1rem);
  }
}

@media (max-width: 768px) {
  .user-profile-rating-box {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .user-profile-rating-content {
    height: auto;
  }

  .user-profile-movie-poster-container {
    width: 60px;
    height: 90px;
    margin-right: 1rem;
  }

  .user-profile-movie-info {
    flex: 1;
  }

  .user-profile-movie-title {
    font-size: 0.9rem;
  }

  .user-profile-movie-rating,
  .user-profile-movie-year {
    font-size: 0.8rem;
  }

  .user-profile-pagination-button {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }
}

.user-profile-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  border-bottom: 1px solid #e0e0e0;
}

.user-profile-nav-desktop {
  display: flex;
  justify-content: center;
}

.user-profile-nav-mobile {
  display: none;
  width: 100%;
}

.user-profile-nav-toggle {
  width: 100%;
  background: none;
  border: none;
  padding: 1rem;
  font-size: 1rem;
  cursor: pointer;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.user-profile-nav-dropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.user-profile-nav-item {
  background: none;
  border: none;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom: 3px solid transparent;
  text-align: left;
}

.user-profile-nav-item:hover {
  background-color: #f0f0f0;
}

.user-profile-nav-item.active {
  border-bottom-color: #BF2722;
  font-weight: bold;
}

.user-profile-ratings-count {
  display: inline-block;
  background-color: #BF2722;
  color: white;
  border-radius: 50%;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  margin-left: 0.5rem;
  font-weight: bold;
  transition: all 0.3s ease;
}

.user-profile-nav-item:hover .user-profile-ratings-count {
  transform: scale(1.1);
}

.user-profile-nav-item.active .user-profile-ratings-count {
  background-color: white;
  color: #BF2722;
}

@media (max-width: 768px) {
  .user-profile-nav-desktop {
    display: none;
  }

  .user-profile-nav-mobile {
    display: block;
  }

  .user-profile-nav {
    border-bottom: none;
  }

  .user-profile-nav-item {
    border-bottom: 1px solid #e0e0e0;
  }

  .user-profile-nav-item:last-child {
    border-bottom: none;
  }

  .user-profile-nav-item.active {
    border-left: 3px solid #BF2722;
    border-bottom-color: #e0e0e0;
  }
}

.user-profile-ratings-grid-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.user-profile-ratings-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}

.user-profile-rating-box {
  flex: 0 0 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  background-color: #3A3A3A;
  border: 1px solid #3A3A3A;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.user-profile-rating-box:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.user-profile-movie-poster-container {
  width: 92px;
  height: 138px;
  flex-shrink: 0;
}

.user-profile-movie-poster {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-profile-movie-poster-placeholder {
  width: 100%;
  height: 100%;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: #666;
}

.user-profile-movie-info {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.user-profile-movie-title {
  font-weight: bold;
  color: #dddddd;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.user-profile-movie-rating {
  color: #BF2722;
  font-weight: bold;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

.user-profile-movie-year {
  color: #666;
  font-size: 0.8rem;
}

.user-profile-pagination {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.user-profile-pagination-button {
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  color: #333;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.user-profile-pagination-button:hover {
  background-color: #e0e0e0;
}

.user-profile-pagination-button.active {
  background-color: #BF2722;
  color: white;
}

@media (max-width: 1200px) {
  .user-profile-rating-box {
    flex: 0 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .user-profile-rating-box {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .user-profile-rating-content {
    height: auto;
  }

  .user-profile-movie-poster-container {
    width: 60px;
    height: 90px;
    margin-right: 1rem;
  }

  .user-profile-movie-info {
    flex: 1;
  }

  .user-profile-movie-title {
    font-size: 0.9rem;
  }

  .user-profile-movie-rating,
  .user-profile-movie-year {
    font-size: 0.8rem;
  }

  .user-profile-pagination-button {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }
}