.review-page {
    width: 100%;
    box-sizing: border-box;
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 1rem;
}

.review-page-header-decoration {
    color: var(--color-white);
    text-decoration: underline;
    cursor: pointer;
    font-size: 1rem;
}

.review-page-header {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
}

.review-page-title {
    font-size: 3rem;
    font-weight: 700;
    margin: 0;
    margin-bottom: 0.2rem;
}

.review-page-subtitle {
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.review-page-meta {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    font-size: 1rem;
}

.review-page-author {
    font-weight: 800;
}

.review-page-date {
    position: relative;
}

.review-page-spoiler-warning {
    color: var(--color-white);
    background-color: var(--color-brand-opacity-20);
    padding: 4px 12px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

.review-page-banner {
    width: 100%;
    margin-bottom: 2rem;
    border-radius: 8px;
    overflow: hidden;
}

.review-page-banner img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.review-page-content {
    line-height: 1.8;
    font-size: 20px;
}

.review-page-content h1,
.review-page-content h2,
.review-page-content h3 {
    margin: 2rem 0 1rem;
}

.review-page-content p {
    margin-bottom: 1.5rem;
}

.review-page-content img {
    max-width: 100%;
    height: auto;
    border-radius: 6px;
    margin: 2rem 0;
}

.review-page-content a {
    text-decoration: none;
    color: var(--color-brand);
}

.review-page-ratings {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin: 2rem 0;
}

.review-page-rating-main {
    position: relative;
    width: 80px;
    height: 80px;
    background-color: var(--color-brand);
    color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: 700;
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    transition: transform 0.2s ease;
}

.review-page-rating-main:hover {
    transform: scale(1.05);
}

.review-page-rating-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
}

.review-page-rating-text {
    font-size: 2rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    animation: fadeIn 0.3s ease-in;
    margin-top: 1rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .review-page {
        padding: 1rem;
    }

    .review-page-title {
        font-size: 2rem;
    }

    .review-page-subtitle {
        font-size: 1.1rem;
    }

    .review-page-meta {
        flex-direction: row;
        gap: 0.5rem;
        align-items: flex-start;
    }

    .review-page-date {
        padding-left: 0;
    }

    .review-page-date::before {
        display: none;
    }

    .review-page-rating-main {
        width: 60px;
        height: 60px;
        font-size: 1.5rem;
    }

    .review-page-rating-text {
        font-size: 1rem;
    }
}