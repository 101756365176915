.watch-trailer-button {
  width: 100%;
  padding: 10px;
  background-color: #e50914;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 16px;
  transition: background-color 0.3s ease;
}

.watch-trailer-button:hover {
  background-color: #f40612;
}

.trailer-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.trailer-content {
  position: relative;
  width: 80%;
  height: 80%;
}

.close-trailer {
  position: absolute;
  top: -40px;
  right: 0;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .trailer-content {
    width: 100%;
    height: 50%;
  }
}