.mobile-navigation-container {
  display: none;
  position: fixed;
  bottom: 12px;
  left: 12px;
  right: 12px;
  border-radius: 12px;
  padding-bottom: 8px;
  padding-top: 8px;
  background-color: #2b2628ec;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  z-index: 1000;
  overflow: hidden;
}

.mobile-navigation-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 10px 0;
  text-decoration: none;
  color: rgb(162, 162, 162);
}

.mobile-navigation-item span {
  font-size: 12px;
}

.mobile-navigation-active {
  color: white;
}

.mobile-navigation-icon {
  font-size: 24px;
  margin-bottom: 4px;
}

@media (max-width: 768px) {
  .mobile-navigation-container {
    display: flex;
  }

  main {
    padding-bottom: 60px;
  }
}