.movies-by-tag {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.movies-by-tag h2 {
  margin-bottom: 1.5rem;
  text-align: center;
}

.movie-list {
  list-style-type: none;
  padding: 0;
}

.movie-item {
  background-color: #d0d0d0;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.movie-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.movie-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.movie-link h3 {
  margin-top: 0;
  color: #f0f0f0;
}

.movie-link p {
  margin: 0.5rem 0;
  color: #ccc;
}

.loading, .error {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: #f0f0f0;
}