.main-footer {
  background-color: #1a1a1a;
  color: #f0f0f0;
  padding: 20px 0;
  font-size: 0.9rem;
  margin-top: 40px;
}

.main-footer .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 20px;
}

.footer-column {
  display: flex;
  flex-direction: column;
}

.copyright {
  margin-bottom: 10px;
}

.tmdb-attribution {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.tmdb-logo {
  width: 40px;
  margin-right: 10px;
}

.tmdb-attribution p {
  margin: 0;
}

.buy-me-coffee {
  text-align: right;
}

@media (max-width: 768px) {
  .main-footer .container {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    align-items: center;
    margin-bottom: 20px;
  }

  .copyright,
  .tmdb-attribution,
  .buy-me-coffee {
    text-align: center;
    margin-bottom: 10px;
  }

  .tmdb-attribution {
    flex-direction: column;
    align-items: center;
  }

  .tmdb-logo {
    margin-right: 0;
    margin-bottom: 10px;
  }
}