.home-desktop {
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    justify-content: space-between;
    gap: 0;
    padding: 0 20px;
    margin-top: 2rem;
}

.home-desktop-sidebar {
    width: 300px;
    min-width: 300px;
    box-sizing: border-box;
    padding: 0 2rem;
}

.home-desktop-main {
    flex-grow: 1;
}

.home-desktop-sidebar h4 {
    margin-bottom: 0.4rem;
    margin-top: 0;
}

.home-desktop-browse-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
}

.home-desktop-browse-items span {
    font-size: 0.9rem;
    cursor: pointer;
}

.home-desktop-browse-items span:hover {
    text-decoration: underline;
}

.home-desktop-browse-see-more {
    font-weight: 300;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    text-decoration: underline;
    text-underline-offset: 0.2rem;
    cursor: pointer;
}

.home-desktop-simple-poster-list {
    display: flex;
    align-items: stretch;
    gap: 1rem;
}

.home-desktop-simple-poster-item {
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
}

.home-desktop-simple-poster-image-container {
    width: 100%;
    max-width: 100%;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    border: 1.5px solid var(--color-brand-opacity-40);
    transition: all 0.4s;
}

.home-desktop-simple-poster-image-container:hover {
    border: 1.5px solid var(--color-brand-opacity-80);
}

.home-desktop-simple-poster-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.home-desktop-simple-poster-item-subtitle {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 0.7rem;
}

.home-desktop-simple-poster-item-subtitle svg {
    color: var(--color-brand-opacity-80);
}

.home-desktop-section {
    margin-bottom: 3rem;
}

.home-mobile-section {
    margin-bottom: 4rem;
}

.home-desktop-section-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 1rem;
}

.home-desktop-section-title h3 {
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.07rem;
    margin: 0;
}

.home-desktop-section-title span {
    font-size: 0.8rem;
    text-decoration: underline;
    text-underline-offset: 0.2rem;
    cursor: pointer;
}

.home-mobile {
    display: none;
    padding: 0px 20px;
    margin-top: 2rem;
}

.home-mobile-section h2 {
    font-size: 1.3rem;
    font-weight: 500;
}

p.home-mobile-general-text {
    margin: 0;
    line-height: 1.5;
}

.home-mobile-section-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1.5rem;
}

.home-mobile-section-title h3 {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.07rem;
    margin: 0;
}

.home-mobile-section-title span {
    font-size: 12px;
    text-decoration: underline;
    text-underline-offset: 0.2rem;
    cursor: pointer;
}

.home-mobile-simple-poster-list {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 0.4rem;
}

.home-mobile-browse-section {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
}

.home-mobile-browse-section span {
    flex-basis: 48%;
    background-color: var(--color-border-grey);
    padding: 12px 20px;
    min-height: 80px;
    font-weight: 600;
    margin-bottom: 1rem;
    font-size: 12px;
    border-radius: 4px;
}

.home-featured-review-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
}

.home-featured-review-poster {
    max-width: 100px;
    width: 100px;
    min-width: 100px;
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
}

.home-featured-review-poster img {
    width: 100%;
}

.home-featured-review-information {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.home-featured-review-information h4 {
    margin: 0;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.3s;
}

.home-featured-review-information h4 svg {
    font-size: 1.5rem;
    color: var(--color-brand);
}

.home-featured-review-information h4:hover {
    gap: 1rem;
}

.home-featured-review-subtitle {
    font-size: 1.5rem;
    color: var(--color-subtitle-grey);
    margin-bottom: 0.8rem;
}

.home-featured-review-authorship {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.home-featured-review-authorship div {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
}

.home-featured-review-authorship div.home-featured-review-spoilers-badge {
    font-size: 12px;
    text-transform: uppercase;
    padding: 4px 16px;
    background-color: #2266bf;
    font-weight: 600;
    letter-spacing: 0.03rem;
    border-radius: 4px;
}

.home-featured-review-authorship div.home-featured-review-spoilers-badge.spoilers {
    background-color: var(--color-brand-opacity-60);
}

@media screen and (max-width: 1200px) {
    .home-desktop-simple-poster-item {
        width: 120px;
    } 
}

@media screen and (max-width: 1040px) {
    .home-desktop-simple-poster-item {
        width: 100px;
    } 
}

@media screen and (max-width: 940px) {
    .home-desktop {
        flex-direction: column-reverse;
    }

    .home-desktop-sidebar {
        padding: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        box-sizing: border-box;
        gap: 4rem;
        width: 100%;
        flex-direction: row;
        margin-top: 2rem;
        padding-top: 2rem;
        border-top: 1px solid var(--color-border-grey);
    }

    .home-desktop-simple-poster-item {
        width: 20%;
    } 

    .home-desktop-browse-items {
    }
}

@media screen and (max-width: 768px) {
    .home-desktop { display: none; }
    .home-mobile { display: block; }


    .home-desktop-simple-poster-item {
        width: 33%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0.5rem;
    }

    .home-desktop-simple-poster-item-subtitle {
        flex-direction: column-reverse;
        gap: 0;
        align-items: flex-start;
    }

    .home-featured-review-container {
        align-items: flex-start;
        gap: 8px;
    }

    .home-featured-review-poster {
        max-width: 60px;
        width: 60px;
        min-width: 60px;
    }

    .home-featured-review-information h4 {
        font-size: 17px;
    }

    .home-featured-review-information h4 svg {
        font-size: 14px;
    }

    .home-featured-review-subtitle {
        font-size: 15px;
    }

    .home-featured-review-authorship div {
        font-size: 12px;
    }

}