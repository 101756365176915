.movie-details {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  color: var(--text-color);
  background-color: var(--background-color);
}

.navigation {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.back-link {
  color: var(--secondary-color);
  text-decoration: none;
  display: flex;
  align-items: center;
}

.back-link:hover {
  text-decoration: underline;
}

.back-link svg {
  margin-right: 5px;
  font-size: 1.2em;
}

.breadcrumb-current {
  margin-left: 5px;
}

/* Remove these styles as they're no longer needed */
.mobile-back,
.desktop-breadcrumbs {
  display: none;
}

@media (max-width: 768px) {
  .navigation {
    font-size: 18px;
  }

  /* Remove any mobile-specific styles for .mobile-back if they exist */
}

.breadcrumbs {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}

.breadcrumbs a {
  color: var(--primary-color);
  text-decoration: none;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.movie-content {
  display: flex;
  flex-direction: row;
  gap: 30px;
  position: relative;
  margin-top: 40px;
}

.movie-poster {
  flex: 0 0 auto;
  width: 300px;
  position: relative;
}

.movie-poster img {
  width: 100%;
  height: auto;
}

.movie-info-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #dcdcdc;
}

.movie-info-meta {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  font-size: 14px;
  font-weight: 300;
  color: #aeaeae;
  padding-bottom: 0.5em;
}

.movie-info-meta-mobile {
  display: none;
}

.movie-info {
  flex: 1;
}

.movie-info h1 {
  margin: 0;
  padding-right: 60px;
  margin-bottom: 0.2em;
  color: var(--text-color);
}

.rating-section {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}

.rating-section-mobile {
  display: none;
}

.rating-section-button {
  color: var(--text-color);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 12px 80px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  font-size: 0.9rem;
}

.trailer-section-button {
  color: var(--primary-color);
  background-color: rgba(191, 39, 34, 0.1);
  /* border: 1.5px solid #BF2722; */
  padding: 4px 12px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.trailer-section-button-mobile {
  display: none;
}

.rating-section-button-inverse {
  color: #BF2722;
  background-color: white;
}

.tags {
  margin-top: 20px;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag-button {
  background-color: var(--secondary-color);
  color: var(--background-color);
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tag-button:hover {
  background-color: var(--hover-color);
}

.info-section {
  margin-bottom: 20px; /* Increased from 20px to 30px */
}

.info-heading {
  text-transform: uppercase;
  color: #9b9b9b;
  font-size: 0.9em;
  margin-bottom: 0px; /* Reduced from 5px to 2px */
  font-weight: 600;
}

.info-section p {
  line-height: 1.5;
  margin: 0;
  margin-top: 0.3em
}

@media (max-width: 768px) {
  .desktop-breadcrumbs {
    display: none;
  }

  .mobile-back {
    display: flex;
  }

  .trailer-section-button-mobile {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .navigation {
    font-size: 18px;
  }

  .movie-content {
    flex-direction: column;
    gap: 10px;
    margin-top: 0px;
  }

  .movie-poster {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .movie-poster img {
    /* max-width: 300px; */
    margin: 0 auto;
    display: block;
  }

  .movie-info {
    margin-top: 0; /* Removed top margin */
  }

  .movie-info-title-container {
    align-items: center;
    border-bottom: unset;
  }

  .movie-info h1 {
    text-align: center;
    margin-top: 5px; /* Reduced from 10px to 5px */
    border-bottom: unset;
    padding-bottom: 0;
    margin: 0;
    padding-right: unset;
  }

  .info-section {
    margin-bottom: 20px; /* Increased from 15px to 25px */
  }

  .info-section:last-of-type {
    margin-bottom: 0px;
  }

  .info-heading {
    margin-bottom: 0px; /* Reduced from 5px to 2px */
  }
}

.info-and-chart-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
}

.info-column {
  flex: 0 1 20%;
  min-width: 150px;
}

.spider-chart-container {
  flex: 1 1 80%;
  min-width: 300px;
}

.spider-chart-container .rating-page-spider-chart {
  width: 100%;
  height: 300px;
}

.user-rating-badge {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: var(--primary-color);
  color: var(--text-color);
  border-top-left-radius: 14px;
  border-bottom-left-radius: 24px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 40px;
  min-width: 40px;
  height: 40px;
  font-weight: 600;
  cursor: default;
}

.movie-details-average-rating-badge {
  position: absolute;
  right: 0;
  top: 60px;
  font-size: 14px;
  font-weight: 300;
  color: var(--text-color);
}

.rating-section-desktop {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  width: 100%;
  margin-top: 40px;
}

.movie-review-section-mobile {
  display: none;
}

.movie-details-review-section {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
  border: 1px solid var(--color-border-grey);
  padding: 14px;
  border-radius: 8px;
}

.movie-review-section-mobile .movie-details-review-section {
  margin-top: 1rem;
}

.movie-details-review-score {
  position: relative;
  width: 44px;
  min-width: 44px;
  height: 44px;
  background-color: var(--color-brand);
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  transition: transform 0.2s ease;
}

.movie-details-review-title {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 4px;
  font-weight: 600;
}

.movie-details-review-title span:last-of-type {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 768px) {

  .movie-details-average-rating-badge,
  .user-rating-badge {
    display: none;
  }

  .movie-review-section-mobile {
    display: block;
  }

  .movie-info-meta-mobile {
    display: flex;
  }

  .rating-section-desktop {
    display: none;
  }

  .rating-section-mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 1em;
    margin-top: 60px;
  }

  .rating-section-mobile .rating-section-button {
    flex-basis: 50%;
    padding-left: 0;
    padding-right: 0;
  }

  .info-and-chart-container {
    flex-direction: column;
  }

  .info-column, .spider-chart-container {
    width: 100%;
  }

  .spider-chart-container {
    margin-top: 20px;
  }

  .spider-chart-container .rating-page-spider-chart {
    height: 250px;
  }

  .user-rating-badge {
    right: 0;
    top: -20px;
  }
}

.rating-section-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f8f8;
}

.error-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.error-content h2 {
  color: #333;
  margin-bottom: 1rem;
}

.error-content p {
  color: #666;
  margin-bottom: 1.5rem;
}

.go-back-button {
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.go-back-button:hover {
  background-color: var(--hover-color);
}

.rating-page-spider-chart {
  position: relative;
}

.ratings-comparison-table {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(26, 26, 26, 0.9);
  color: var(--text-color);
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.ratings-comparison-table table {
  border-collapse: collapse;
}

.ratings-comparison-table th,
.ratings-comparison-table td {
  padding: 5px 10px;
  text-align: left;
  font-size: 12px;
}

.ratings-comparison-table th {
  font-weight: bold;
}

@media (max-width: 768px) {
  .ratings-comparison-table {
    display: none;
  }
}

.movie-poster {
  position: relative;
}

.poster-placeholder {
  width: 100%;
  padding-top: 150%; /* 2:3 aspect ratio for movie posters */
  background-color: #2a2a2a; /* Light grey background */
  position: relative;
  overflow: visible; /* Changed from hidden to visible to allow shadow to show */
  border-radius: 8px;
}

.poster-placeholder img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.poster-placeholder img.loaded {
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.poster-placeholder img.loaded:not([style*="height: 100%"]) {
  bottom: 0;
  top: auto;
}

.no-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  padding: 8px;
  font-size: 1rem;
  color: var(--text-color);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.movie-info-title-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
}

.movie-info-title-and-meta {
  flex-grow: 1;
  margin-right: 20px;
}

.movie-info-ratings {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}

.rating-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rating-label {
  font-size: 0.8em;
  color: #666;
}

.rating-label svg {
  font-size: 24px;
}

.rating-value {
  font-size: 20px;
  font-weight: 700;
  height: 28px;
  display: flex;
  align-items: flex-end;
  color: var(--text-color);
}

.rating-value span {
  font-size: 13px;
  font-weight: 300;
}

/* Remove these classes as they're no longer used */
.user-rating-badge,
.movie-details-average-rating-badge {
  display: none;
}

.movie-info-title-and-meta-mobile {
  display: none;
}

/* Adjust for mobile screens */
@media (max-width: 768px) {
  .movie-info-title-and-meta {
    display: none;
  }

  .movie-info-title-and-meta.movie-info-title-and-meta-mobile {
    display: block;
    margin-bottom: 12px;
  }

  .movie-info-title-and-meta-mobile h1 {
    text-align: center;
    margin-top: 5px;
    border-bottom: unset;
    padding-bottom: 0;
    margin: 0;
    padding-right: unset;
  }

  .movie-info-title-row {
    flex-direction: column;
    margin-bottom: 8px;
  }

  .movie-info-ratings {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    gap: 3rem;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    padding: 8px;
  }

  .movie-info-meta-desktop {
    display: none;
  }

  .movie-info-meta-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    font-size: 14px;
    font-weight: 300;
    color: rgb(70, 70, 70);
    margin-top: 10px;
  }

  .movie-info-title-and-meta {
    text-align: center;
    margin-right: 0;
    width: 100%;
  }

  .movie-info-meta {
    justify-content: center;
  }

  .rating-box {
    width: 60px;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(191, 39, 34, 0.3);
  border-radius: 50%;
  border-top-color: var(--primary-color);
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

h2.movie-details-section-title {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--secondary-color);
}

.cast-carousel {
  position: relative;
}

.cast-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.cast-member {
  width: calc(25% - 15px);
  text-align: center;
  margin-bottom: 0px;
  cursor: pointer;
}

.cast-portrait {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 10px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cast-portrait img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder-portrait {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  color: #999;
  background-color: #e0e0e0;
}

.cast-name {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
}

.cast-character {
  font-size: 12px;
  color: #aeaeae;
}

.see-all-cast {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: var(--secondary-color);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: var(--background-color);
  transition: background-color 0.3s;
}

.see-all-cast:hover {
  background-color: var(--hover-color);
}

.cast-section-mobile {
  display: none;
}

@media (max-width: 768px) {
  .cast-section-desktop {
    display: none;
  }

  .cast-section-mobile {
    display: block;
  }

  .cast-carousel {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }

  .cast-carousel::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

  .cast-list {
    display: inline-flex;
    flex-wrap: nowrap;
    padding: 20px 0;
  }

  .cast-member {
    display: inline-block;
    width: 100px;
    margin-right: 2px;
    white-space: normal;
  }

  .cast-portrait {
    width: 80px;
    height: 80px;
  }

  .cast-name {
    font-size: 12px;
  }

  .cast-character {
    font-size: 10px;
  }

  .see-all-cast {
    display: none;
  }
}

.streaming-section {
  margin-top: 20px;
}

.streaming-list {
  padding: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.streaming-provider {
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.streaming-provider:hover {
  transform: scale(1.05);
}

.streaming-provider img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-bottom: 5px;
}

.streaming-provider span {
  font-size: 12px;
}

@media (max-width: 768px) {
  .rating-section-desktop .streaming-section {
    display: none;
  }
}

@media (min-width: 769px) {
  .cast-section-mobile .streaming-section {
    display: none;
  }
}