.professional-identity-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.professional-identity-profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.professional-identity-profile-picture {
  width: 100%;
  max-width: 780px;
  height: auto;
  object-fit: cover;
  margin-bottom: 20px;
}

.professional-identity-profile-info {
  text-align: center;
}

.professional-identity-profile-info h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.professional-identity-biography {
  margin-bottom: 40px;
}

.professional-identity-biography h2 {
  font-size: 1.8em;
  margin-bottom: 15px;
}

.professional-identity-credits-list {
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.professional-identity-credit-item {
  display: flex;
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
}

.professional-identity-movie-poster {
  width: 154px;
  height: 231px;
  object-fit: cover;
}

.professional-identity-credit-details {
  flex-grow: 1;
  padding: 15px;
}

.professional-identity-credit-details h3 {
  margin-top: 0;
}

.professional-identity-loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: professional-identity-spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes professional-identity-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.professional-identity-error-message {
  color: red;
  text-align: center;
  margin-top: 20px;
}

.professional-identity-mobile {
  display: none;
}

.professional-identity-desktop {
  display: block;
}

.professional-identity-desktop-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.professional-identity-desktop-top-section {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 40px;
}

.professional-identity-desktop-profile-picture {
  max-width: 300px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.601);
}

.professional-identity-desktop-info {
  flex: 1;
}

.professional-identity-desktop-name {
  font-size: 2.5em;
  margin-bottom: 20px;
  margin-top: 0;
}

.professional-identity-desktop-biography {
  font-size: 1rem;
  line-height: 1.6;
  white-space: pre-wrap;
}

.professional-identity-desktop-credits-section h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.professional-identity-desktop-credits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(154px, 1fr));
  gap: 20px;
}

.professional-identity-desktop-credit-item {
  text-decoration: none;
  color: inherit;
  border: 1px solid transparent;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
}

.professional-identity-desktop-credit-item:hover {
  transform: translateY(-5px);
}

.professional-identity-desktop-poster-container {
  width: 100%;
  padding-top: 150%; /* 2:3 aspect ratio for movie posters */
  position: relative;
}

.professional-identity-desktop-movie-poster,
.professional-identity-desktop-placeholder-poster {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.professional-identity-desktop-placeholder-poster {
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
}

.professional-identity-desktop-placeholder-poster span {
  font-size: 0.9em;
  color: #666;
  word-break: break-word;
}

.professional-identity-desktop-credit-details {
  padding-top: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.professional-identity-desktop-credit-details h3 {
  margin-top: 0;
  font-size: 1em;
  margin-bottom: 5px;
}

.professional-identity-desktop-credit-details p {
  margin: 2px 0;
  font-size: 0.8em;
}

@media (max-width: 768px) {
  .professional-identity-mobile {
    display: block;
  }

  .professional-identity-desktop {
    display: none;
  }
}

@media (max-width: 768px) {
  .professional-identity-credits-list {
    grid-template-columns: 1fr;
  }
}

.professional-identity-mobile-container {
  max-width: 100%;
  padding-bottom: 40px;
}

.professional-identity-mobile-top-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.professional-identity-mobile-name {
  font-size: 2em;
  margin-bottom: 20px;
  margin-top: 0;
  text-align: center;
}

.professional-identity-mobile-image-container {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
}

.professional-identity-mobile-profile-picture {
  width: 100%;
  height: auto;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.professional-identity-mobile-placeholder-image {
  width: 100%;
  height: 0;
  padding-bottom: 150%; /* 2:3 aspect ratio */
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #666;
  font-size: 1em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.professional-identity-mobile-biography {
  font-size: 1rem;
  line-height: 1.6;
  text-align: left;
  margin-bottom: 30px;
  white-space: pre-wrap;
}

.professional-identity-mobile-credits-section h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center;
}

.professional-identity-mobile-credits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 15px;
}

.professional-identity-mobile-credit-item {
  text-decoration: none;
  color: inherit;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.professional-identity-mobile-poster-container {
  width: 100%;
  padding-top: 150%; /* 2:3 aspect ratio for movie posters */
  position: relative;
}

.professional-identity-mobile-movie-poster,
.professional-identity-mobile-placeholder-poster {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.professional-identity-mobile-placeholder-poster {
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
}

.professional-identity-mobile-placeholder-poster span {
  font-size: 0.8em;
  color: #666;
  word-break: break-word;
}

.professional-identity-mobile-credit-details {
  padding: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.professional-identity-mobile-credit-details h3 {
  margin-top: 0;
  font-size: 0.9em;
  margin-bottom: 5px;
}

.professional-identity-mobile-credit-details p {
  margin: 2px 0;
  font-size: 0.8em;
}