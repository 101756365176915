.movie-searchbar-container {
  max-width: 600px;
  /* width: 90%;
  min-width: 200px; */
}

.movie-searchbar-form {
  display: flex;
  border: 2px solid #92ABB5;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
}

.movie-searchbar-input {
  flex-grow: 1;
  border: none;
  font-size: 16px;
  padding-left: 1.2rem;
  outline: none;
  width: 100%;
  background-color: white;
}

.movie-searchbar-button,
.movie-searchbar-clear-button {
  background-color: #BF2722;
  color: white;
  border: none;
  padding: 0.75rem 1.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.movie-searchbar-button[type="submit"],
button.movie-searchbar-button[type="submit"]:hover {
  margin-top: 0;
  background-color: white;
  border-radius: 0;
}

button.movie-searchbar-button[type="submit"] svg {
  color: #838383;
}

.movie-searchbar-clear-button {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: #999;
  padding: 0.5rem;
}

@media (max-width: 768px) {
  .movie-searchbar-container {
    width: 95%;
  }

  .movie-searchbar-input {
    padding: 0.5rem 0.75rem;
    font-size: 16px;
  }

  .movie-searchbar-button,
  .movie-searchbar-clear-button {
    padding: 0.5rem 1rem;
  }

  .movie-searchbar-button svg,
  .movie-searchbar-clear-button svg {
    width: 1em;
    height: 1em;
  }
}

@media (max-width: 480px) {
  .movie-searchbar-container {
    width: 100%;
    padding: 0 0.5rem;
  }

  .movie-searchbar-form {
    border-radius: 20px;
  }

  .movie-searchbar-input {
    padding: 0.5rem;
    padding-left: 16px;
    font-size: 16px;
  }

  .movie-searchbar-button,
  .movie-searchbar-clear-button {
    padding: 0.5rem 0.75rem;
  }
}