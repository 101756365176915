.mobile-profile-nav-menu {
    display: flex;
    align-items: center;
    padding: 10px 0;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .mobile-profile-nav-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .mobile-profile-nav-item {
    margin-right: 20px;
  }
  
  .mobile-profile-nav-button {
    background: none;
    border: none;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #efefef;
    padding: 5px 0;
    cursor: pointer;
    transition: opacity 0.2s;
  }
  
  .mobile-profile-nav-button.active {
    border-bottom: 2px solid #efefef;
  }
  
  .mobile-profile-nav-button:hover {
    opacity: 0.7;
  }
    
  @media (min-width: 768px) {
    .mobile-profile-nav-menu {
      justify-content: center;
    }
  }
  
  /* Ensure touch targets are large enough */
  .mobile-profile-nav-button {
    min-height: 44px;
    min-width: 44px;
  }
  
  .desktop-nav-menu {
    display: none;
  }

  @media (min-width: 768px) {
    .desktop-nav-menu {
      display: block;
    }

    .desktop-nav-items {
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      padding: 10px 0;
      margin-bottom: 20px;
    }

    .desktop-nav-item {
      background: none;
      border: none;
      font-family: sans-serif;
      font-size: 18px;
      font-weight: 600;
      color: #8f8f8f;
      cursor: pointer;
      transition: opacity 0.2s;
      position: relative;
      padding-bottom: 8px;
      border-bottom: 2px solid transparent;
    }

    .desktop-nav-item.active {
      border-bottom: 2px solid #d3d3d3;
      color: #d3d3d3;
    }

    .desktop-nav-item:hover {
      opacity: 0.9;
    }
  }