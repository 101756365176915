.search-results {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.search-results-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.search-results-movie-link {
  text-decoration: none;
  color: inherit;
  width: 200px;
}

.search-results-movie {
  width: 100%;
  text-align: center;
  transition: transform 0.2s ease-in-out;
}

.search-results-movie:hover {
  transform: scale(1.05);
}

.search-results-movie-poster img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.search-results-movie-info {
  margin-top: 10px;
}

.search-results-movie-title {
  font-size: 1rem;
  margin: 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-results-movie-year {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

.search-results-movie-overview {
  display: none;
}

.search-results-mobile {
  display: none;
}

@media (max-width: 768px) {
  .search-results-mobile { display: block }
  
  .search-results-container {
    flex-direction: column;
    gap: 10px;
  }

  .search-results-movie-link {
    width: 100%;
  }

  .search-results-movie {
    display: flex;
    text-align: left;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }

  .search-results-movie .search-results-movie-poster {
    width: 100px;
    flex-shrink: 0;
  }

  .search-results-movie .search-results-movie-poster img {
    height: 150px;
    object-fit: cover;
  }

  .search-results-movie-info {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }

  .search-results-movie-title {
    font-size: 1.1rem;
    margin-bottom: 5px;
  }

  .search-results-movie-year {
    margin-bottom: 5px;
  }

  .search-results-movie-overview {
    display: block;
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}