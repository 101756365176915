.featured-keyword-container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 20px;
}

.featured-keyword-title {
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: 400;
  color: #e2e2e2;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
  text-align: left;
  text-transform: capitalize;
}

.featured-keyword-top-movies,
.featured-keyword-other-movies {
  margin-bottom: 40px;
}

.featured-keyword-other-movies,
.featured-keyword-title {
  width: 100%;
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
}

.featured-keyword-title-with-logo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.featured-keyword-title-logo {
  height: 3rem;
  border-radius: 12px;
  overflow: hidden;
}

.featured-keyword-title-logo img {
  height: 100%;
}

.featured-keyword-top-movies h2,
.featured-keyword-other-movies h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.featured-keyword-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
}

.featured-keyword-grid-item {
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.featured-keyword-grid-item:hover {
  transform: scale(1.05);
  z-index: 2;
}

.featured-keyword-poster-container {
  position: relative;
  overflow: hidden;
}

.featured-keyword-poster-item-subtitle {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  margin-top: 0.5rem;
}

.featured-keyword-poster-item-subtitle svg {
  color: var(--color-brand-opacity-80);
}

.featured-keyword-poster {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.featured-keyword-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 5px;
}

.featured-keyword-pagination button {
  min-width: 40px;
  height: 40px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
}

.featured-keyword-pagination button:hover:not(:disabled) {
  background-color: #e0e0e0;
}

.featured-keyword-pagination button.active {
  background-color: #BF2722;
  color: white;
  border-color: #BF2722;
}

.featured-keyword-pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-nav-button {
  font-size: 18px;
}

.pagination-ellipsis {
  padding: 5px;
}

.featured-keyword-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .featured-keyword-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  .featured-keyword-title {
    font-size: 2em;
  }

  .featured-keyword-top-movies h2,
  .featured-keyword-other-movies h2 {
    font-size: 1.5em;
  }

  .featured-keyword-pagination {
    gap: 3px;
  }

  .featured-keyword-pagination button {
    min-width: 35px;
    height: 35px;
    font-size: 12px;
  }

  .pagination-nav-button {
    font-size: 16px;
  }
}

.featured-keyword-poster-item {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.featured-keyword-poster-item:hover {
  transform: scale(1.05);
  z-index: 2;
}

.featured-keyword-poster-image-container {
  width: 100%;
  max-width: 100%;
  border-radius: 12px;
  overflow: hidden;
  border: 1.5px solid var(--color-brand-opacity-40);
  transition: all 0.4s;
}

.featured-keyword-poster-image-container:hover {
  border: 1.5px solid var(--color-brand-opacity-80);
}

.featured-keyword-poster-image-container img {
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}

.featured-keyword-poster-subtitle {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  margin-top: 0.5rem;
}

.featured-keyword-poster-subtitle svg {
  color: var(--color-brand-opacity-80);
}

@media (max-width: 768px) {
  .featured-keyword-poster-item {
    width: 100%;
  }

  .featured-keyword-poster-subtitle {
    flex-direction: column-reverse;
    gap: 0;
    align-items: flex-start;
  }
}