* {
  box-sizing: border-box;
}

body {
  font-family: 'SUSE', sans-serif;
}

h1, h2, h3, h4, h5, h6, p, span, div, button, input, textarea, select {
  font-family: 'SUSE', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}