.action-row {
  display: flex;
  align-items: center;
  gap: 36px;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 20px;
}

.action-row-mobile {
    display: none;
}

.action-icon {
  font-size: 24px;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  min-width: 60px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #47474752;
}

.action-row-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    width: 70px;
}

.action-row-item p {
    font-size: 12px;
    margin: 0;
    font-weight: 600;
    letter-spacing: 0.05em;
    color: #787878;
    text-transform: uppercase;
}

.action-icon.in-list {
  background-color: #7ab18e;
  border-radius: 20px;
}

.action-icon svg {
    color: white;
}

.action-icon.watched {
  background-color: #689cd4;
  border-radius: 20px;
}

.watched-button-container {
  position: relative;
}

.watched-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #4A636D;
  transition: all 0.3s ease;
}

.nothing {
    color: #929292;
}

.watched-button.watched {
  background-color: #4A636D;
  color: white;
}

.watched-button:hover {
  background-color: #e0e0e0;
}

.watched-button.watched:hover {
  background-color: #3A535D;
}

.watched-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  overflow: hidden;
}

.watched-menu button {
  display: block;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  color: #4A636D;
  transition: background-color 0.3s ease;
}

.watched-menu button:hover {
  background-color: #f0f0f0;
}

.watch-date {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: #666;
}

@media (max-width: 768px) {
  .action-row {
    flex-direction: row;
    justify-content: center;
    gap: 30px;
  }

  .action-row-mobile {
    display: flex;
    justify-content: space-evenly;
    flex-grow: 1;
  }

  .watched-menu {
    width: 100%;
  }
}