.release-calendar {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #2B2628;
}

/* Desktop view */
.desktop-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.calendar-container {
  flex-grow: 1;
  height: 600px; /* Set a fixed height */
  min-height: 600px; /* Ensure minimum height */
  position: relative; /* Add this to make it a positioning context for the overlay */
}

/* Ensure the Calendar component takes full height of its container */
.calendar-container .rbc-calendar {
  height: 100% !important;
}

/* Mobile view */
.mobile-view {
  display: none;
}

.month-navigator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.month-navigator .nav-button {
  background-color: #BF2722;
  color: #ffffff;
  border: none;
  padding: 0.3rem 0.6rem;
  font-size: 0.8rem;
  cursor: pointer;
}

.month-navigator .current-month {
  margin: 0;
  font-size: 1rem;
  color: #e1e1e1;
}

.movie-list {
  list-style-type: none;
  padding: 0;
}

.movie-list li {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  background-color: #C6D8D3;
  border-radius: 4px;
}

.movie-list li:hover {
  background-color: #EF6E6B;
}

.movie-date {
  min-width: 70px;
  margin-right: 1rem;
  font-weight: bold;
}

.movie-title {
  flex-grow: 1;
  color: rgb(217, 217, 217);
}

/* Customize react-big-calendar styles */
.rbc-calendar {
  color: #2B2628;
  width: 100%; /* Ensure calendar takes full width of its container */
}

.rbc-header {
  background-color: #e1e1e1;
  color: #2B2628;
  padding: 8px 0;
}

.rbc-off-range-bg {
  background-color: #e6e6e6;
}

.rbc-today {
  background-color: #EF6E6B;
}

.rbc-event {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2px;    /* Add top margin */
  margin-bottom: 2px; /* Add bottom margin */
  width: 90%; /* This ensures the total width including margins is 100% */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #FFFFFF;
}

.rbc-event-content {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  padding: 2px 4px; /* Add some padding for better readability */
}

/* Increase cell size to accommodate more events */
.rbc-month-view .rbc-month-row {
  min-height: unset;
  height: auto !important;
}

.rbc-row-content {
  padding: 2px 4px;
}

/* Style for the "show more" button */
.rbc-show-more {
  display: none !important;
}

.rbc-btn-group button {
  background-color: #e1e1e1;
  color: #2B2628;
  font-weight: 500;
}

.rbc-btn-group button:hover {
  border: 1px solid transparent;
}

.rbc-toolbar-label {
  font-size: 1.5em;
  font-weight: bold;
  /* color: #2B2628; */
  color: #e7e7e7;
}

.rbc-month-view {
  flex: 1;
  position: relative; /* Make this a positioning context */
}

.rbc-month-row {
  min-height: 100px;
  overflow: visible !important;
}

.rbc-row-content {
  z-index: 1;
}

.rbc-date-cell {
  padding-bottom: 5px;
}

.rbc-event {
  z-index: 2;
}

.rbc-row-segment {
  padding: 0 1px;
}

.rbc-event-content {
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.2;
}

/* Media query for larger screens */
@media (min-width: 1200px) {
  .release-calendar {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-right: 1rem;
    padding-left: 1rem;
    height: 700px; /* Increase height for larger screens */
  }

  .calendar-container {
    height: 100%; /* Take full height of parent */
  }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: block;
  }

  .release-calendar {
    max-width: 100%; /* Full width on mobile */
    padding: 0 20px; /* Add some padding on mobile */
  }

  .month-navigator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  .month-navigator .nav-button {
    font-size: 0.9rem;
    padding: 0.3rem 0.6rem;
    border: none;
    border-radius: 4px;
  }

  .month-navigator .current-month {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    border-left: unset;
    background-color: unset;
  }

  .movie-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .movie-grid-item {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s;
  }

  .movie-grid-item:hover {
    transform: scale(1.05);
  }

  .movie-poster {
    width: 100%;
    overflow: hidden;
  }

  .movie-poster img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .no-poster {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2c2c2c;
    color: #f0f0f0;
  }

  .movie-grid-info {
    padding: 0.5rem;
  }

  .movie-title {
    font-size: 0.9rem;
    margin: 0 0 0.3rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .movie-date {
    font-size: 0.8rem;
    color: #aaaaaa;
    margin: 0;
  }

  .loading-spinner {
    margin: 2rem auto;
  }
}

.loading-spinner {
  border: 4px solid #C6D8D3;
  border-top: 4px solid #EF6E6B;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.event-popup {
  position: absolute;
  z-index: 1000;
  background-color: #F5F5F5;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  padding: 10px;
  width: 480px; /* Increased from 300px to 480px */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  color: #2B2628;
}

.event-popup-content {
  display: flex;
  gap: 15px; /* Increased gap for better spacing */
}

.event-popup-poster {
  width: 120px; /* Slightly increased poster width */
  height: auto;
  object-fit: cover;
}

.event-popup-details {
  flex: 1;
  overflow: hidden;
}

.event-popup h3 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 1.1rem; /* Slightly increased font size */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2B2628;
}

.event-popup-date {
  font-size: 0.9rem;
  color: #2B2628;
  margin-bottom: 8px;
}

.event-popup-overview {
  font-size: 0.9rem;
  margin-bottom: 0;
  max-height: 120px; /* Set a max height */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6; /* Limit to 6 lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  color: #2B2628;
}

/* Assuming you have a class for calendar cells */
.calendar-cell {
  position: relative;
  /* ... other existing styles ... */
}

/* Adjust the event container within the cell */
.calendar-event {
  width: 90%; /* Set width to 90% of the cell */
  margin: 0 auto; /* Center the event horizontally */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  white-space: nowrap; /* Prevent wrapping */
  /* ... other existing styles ... */
}

/* If you have a separate class for the event title */
.calendar-event-title {
  width: 100%; /* Take full width of the event container */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Ensure this only applies to desktop */
@media (min-width: 769px) {
  .calendar-event {
    width: 90%;
  }
}

.rbc-event {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2px;    /* Add top margin */
  margin-bottom: 2px; /* Add bottom margin */
  width: 90%; /* This ensures the total width including margins is 100% */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-event-content {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  padding: 2px 4px; /* Add some padding for better readability */
}

/* Ensure this only applies to desktop */
@media (min-width: 769px) {
  .rbc-event {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2px;
    margin-bottom: 2px;
  }
}

.nothing {
  color: #2c2c2c;
  color: #EF6E6B; /* Old color for background=*/
  color: #4c4c4c;
  color: #fcf6eb;
  color: #2c2c2c; /* Old background color of calendar */
  color: #f5f5f5;
  color: #e8e8e8;
}

.calendar-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: none; /* This allows clicks to pass through to the calendar */
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add these new styles to ensure the loading overlay only covers the calendar cells */
.rbc-month-view {
  position: relative; /* Make this a positioning context */
}

.calendar-loading-overlay {
  top: 37px; /* Adjust this value to match the height of your calendar header */
  left: 0;
  right: 0;
  bottom: 0;
}