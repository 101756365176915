.movie-detail-keywords {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
  position: relative;
  max-height: none; /* Remove the fixed height */
  overflow: visible; /* Allow content to be visible */
  transition: max-height 0.3s ease;
}

.keyword {
  background-color: #bf2722b3;
  color: #fefefe;
  text-transform: uppercase;
  padding: 4px 8px;
  border-radius: 2px;
  font-size: 10px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.keyword:hover {
  background-color: #bf2722d5;
}

.show-all-button {
  background-color: #e0e0e0;
  color: grey;
  font-weight: 500;
}

/* Add these new rules for the desktop version */
.movie-detail-keywords-desktop .movie-detail-keywords {
  max-height: 40px; /* Set a fixed height for desktop */
  /* overflow: hidden; */
}

.movie-detail-keywords-desktop .movie-detail-keywords.show-all {
  max-height: none; /* Remove height restriction when showing all */
  overflow: visible;
}

@media (max-width: 768px) {
  .movie-detail-keywords {
    justify-content: center;
  }
}