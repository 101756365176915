.rating-page-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 1000;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.rating-page-container {
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 20px;
  font-family: 'SUSE', sans-serif;
  flex: 1;
  position: relative;
}

.rating-page-navigation {
  margin-bottom: 20px;
}

.rating-page-back-link {
  text-decoration: none;
  color: #007bff;
}

.rating-page-desktop-breadcrumbs {
  display: none;
}

.rating-page-mobile-back {
  display: flex;
  align-items: center;
}

.rating-page-movie-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.rating-page-movie-info img {
  margin-right: 20px;
}

.rating-page-rating-actions {
  background-color: var(--background-color);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
  margin-top: 40px;
  margin-bottom: 100px;
}

.rating-page-save-rating {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background-color: #BF2722;
  color: white;
  padding: 8px 0px;
  cursor: pointer;
  font-weight: 600;
  border-radius: 4px;
}

.rating-page-cancel-rating {
  font-weight: 400;
  padding: 8px;
  cursor: pointer;
}

.rating-page-back-to-movie {
  display: block;
  text-align: center;
}

.rating-page-spider-chart {
  height: 600px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.rating-page-detailed-ratings-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 80px;
}

.rating-page-detailed-ratings-container .rating-page-category {
  border: 1.5px solid #9bc0b164;
  border-radius: 12px;
  padding: 20px;
  box-sizing: border-box;
}

.rating-page-detailed-instructions {
  text-align: center;
  margin-top: 20px;
  font-style: italic;
  color: #666;
  font-size: 16px;
}

.rating-page-detailed-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.rating-page-detailed-item {
  width: 45%;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}

.rating-page-detailed-item span:last-child {
  font-weight: bold;
  color: #007bff;
}

.rating-page-category {
  margin-bottom: 30px;
}

.rating-page-category h4,
.rating-page-container-heading {
  text-transform: uppercase;
  text-align: center;
  font-size: 1rem;
  font-weight: 800;
  letter-spacing: 0.03em;
  margin: 0;
  margin-bottom: 8px;
  /* color: #9BC0B1; */
  color: rgb(224, 224, 224);
}

.rating-page-category p {
  text-align: center;
  color: #aeaeae;
  font-weight: 400;
  margin: 0;
  margin-bottom: 12px;
}

.rating-page-box-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
}

.rating-page-box {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.rating-page-box svg {
  font-size: 32px;
  width: 32px;
  min-width: 32px;
  /* color: #4A636D; */
  color: #c7d9e0;
}

.rating-page-badge {
  background-color: #9BC0B1;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-weight: bold;
}

.rating-page-box-current-number {
  font-size: 20px;
  text-align: center;
  margin-top: 12px;
  color: rgb(224, 224, 224);
  height: 25px;
}

.rating-page-box-current-number-unrated {
  color: grey;
  font-size: 14px;
}

.user-rating-desktop,
.movie-detail-keywords-desktop {
  display: block;
}

.movie-detail-keywords-mobile {
  display: none;
}

@media (min-width: 768px) {
  .rating-page-desktop-breadcrumbs {
    display: inline;
  }

  .rating-page-mobile-back {
    display: none;
  }
}

@media (max-width: 768px) {
  .movie-detail-keywords-mobile {
    display: block;
  }

  .rating-box.user-rating-desktop,
  .movie-detail-keywords-desktop {
    display: none;
  }

  .rating-page-container {
    margin-top: 20px;
  }

  .rating-page-desktop-breadcrumbs {
    display: inline;
  }

  .rating-page-mobile-back {
    display: none;
  }

  .rating-page-box-container {
    justify-content: space-between;
  }

  .rating-page-box {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .rating-page-box svg {
    font-size: 20px;
    width: 20px;
    min-width: 20px;
    /* color: #4A636D; */
  }

}

.rating-page-close {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 24px;
  color: var(--text-color);
  cursor: pointer;
  z-index: 1001;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(155, 192, 177, 0.1);
  transition: all 0.2s;
}

.rating-page-close:hover {
  background-color: rgba(155, 192, 177, 0.2);
}