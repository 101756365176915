.desktop-user-list-watched-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.desktop-user-list-watched-section {
    width: 100%;
}

.desktop-user-list-watched-section-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.desktop-user-list-watched-section-header h2 {
    margin-left: 10px;
    font-size: 1.2rem;
}

.desktop-user-list-watched-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
}

.desktop-user-list-no-movies {
    font-style: italic;
    color: #777;
    margin-left: 24px;
}

.desktop-user-list-filter {
    position: relative;
}

.filter-dropdown-container {
    position: relative;
}

.filter-dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 120px;
    /* border: 1px solid rgb(212, 212, 212); */
    background-color: #1a1a1a;
    gap: 8px;
    padding: 10px 15px;
    border-radius: 24px;
    cursor: pointer;
    font-size: 14px;
}

.filter-dropdown-toggle svg {
    font-size: 20px;
    color: #BF2722;
}

.filter-dropdown-toggle .rotate {
    transform: rotate(180deg);
}

.filter-dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 300px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Increase this value to ensure it's above other elements */
    max-height: 300px;
    overflow-y: auto;
}

.filter-dropdown-actions {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.filter-dropdown-actions button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 14px;
}

.filter-checkbox-label {
    display: block;
    padding: 10px 15px;
    cursor: pointer;
}

.filter-checkbox-label:hover {
    background-color: #f5f5f5;
}

.filter-checkbox-label input {
    margin-right: 10px;
}

.desktop-user-list-filter h3 {
    margin-bottom: 10px;
}

.desktop-user-list-filter label {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 5px;
}

.desktop-user-list-filter input[type="checkbox"] {
    margin-right: 14px;
}

.desktop-user-list-filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 20px;
}

.filter-checkbox-container {
    display: flex;
    flex-direction: column;
    color: #1a1a1a;
}

.streaming-now-checkbox {
    display: flex;
    align-items: center;
}

.streaming-now-checkbox label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.streaming-now-checkbox input {
    margin-right: 5px;
}

.user-list-item-streaming-platforms {
    border-top: 1px solid #eee;
    padding-top: 4px;
}

.user-list-item-streaming-platforms h4 {
    margin-bottom: 2px;
    margin-top: 2px;
    font-size: 0.9em;
}

.user-list-item-streaming-platforms-list {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.user-list-item-streaming-platforms-list span {
    font-size: 12px;
    background-color: rgb(93, 44, 91);
    color: white;
    border-radius: 24px;
    padding: 2px 12px;
}

/* Mobile Filter Styles */
.mobile-filter-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999; /* Ensure it's above all other elements */
  display: flex;
  justify-content: flex-end;
}

.mobile-filter-side-panel {
  width: 100%;
  background-color: white;
  color: var(--color-background-dark);
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.mobile-filter-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #eee;
  background-color: white;
}

.mobile-filter-panel-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.mobile-filter-panel-close {
  border: none;
  background: none;
  padding: 8px;
  cursor: pointer;
  font-size: 24px;
  color: #666;
}

.mobile-filter-panel-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 20px;
  background-color: white;
}

.mobile-filter-streaming-toggle {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 15px;
  border-bottom: 1px solid #eee;
  font-size: 16px;
  cursor: pointer;
  background-color: #f8f8f8;
  border-radius: 8px;
  margin-bottom: 20px;
}

.mobile-filter-streaming-toggle svg {
  font-size: 24px;
  color: #BF2722;
}

.mobile-filter-streaming-toggle input[type="checkbox"],
.mobile-filter-platform-item input[type="checkbox"] {
  width: 22px;
  height: 22px;
  border-radius: 4px;
  border: 2px solid var(--color-brand);
  cursor: pointer;
  appearance: none;
  background-color: white;
  margin: 0;
  display: grid;
  place-content: center;
}

.mobile-filter-streaming-toggle input[type="checkbox"]:checked,
.mobile-filter-platform-item input[type="checkbox"]:checked {
  background-color: var(--color-brand);
  border-color: var(--color-brand);
}

.mobile-filter-streaming-toggle input[type="checkbox"]:checked::before,
.mobile-filter-platform-item input[type="checkbox"]:checked::before {
  content: "";
  width: 10px;
  height: 10px;
  transform: scale(1);
  background-color: white;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.mobile-filter-platforms {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mobile-filter-platform-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 15px;
  cursor: pointer;
  background-color: white;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.mobile-filter-platform-item:active {
  background-color: #f8f8f8;
}

.mobile-filter-panel-footer {
  padding: 20px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
}

.mobile-filter-panel-footer button {
  padding: 14px 28px;
  border-radius: 24px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.mobile-filter-panel-footer button:first-child {
  background-color: #f8f8f8;
  color: #666;
}

.mobile-filter-panel-footer button:last-child {
  background-color: #BF2722;
  color: white;
}

.mobile-filter-panel-footer button:active {
  opacity: 0.8;
}

.mobile-filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.mobile-filter-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  background-color: #1a1a1a;
  color: white;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
}

.mobile-filter-toggle svg {
  font-size: 18px;
  color: #BF2722;
}

.mobile-filter-count {
  background-color: #BF2722;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.mobile-filter-clear {
  border: none;
  color: var(--color-white);
  text-decoration: underline;
  background: none;
  padding: 4px;
  font-size: 14px;
  cursor: pointer;
}

.mobile-filter-section {
  padding: 20px 0;
}

.mobile-filter-section-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 15px 0;
  color: var(--color-background-dark);
}

.mobile-filter-divider {
  margin: 0;
  border: none;
  border-top: 1px solid #eee;
}

.mobile-filter-sort-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mobile-filter-sort-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 15px;
  cursor: pointer;
  background-color: white;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.mobile-filter-sort-item:active {
  background-color: #f8f8f8;
}

.mobile-filter-sort-item input[type="radio"] {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 2px solid var(--color-brand);
  cursor: pointer;
  appearance: none;
  background-color: white;
  margin: 0;
  display: grid;
  place-content: center;
}

.mobile-filter-sort-item input[type="radio"]:checked {
  background-color: var(--color-brand);
  border-color: var(--color-brand);
}

.mobile-filter-sort-item input[type="radio"]:checked::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(1);
  background-color: white;
}

/* Update the Clear All button to handle both filter and sort */
.mobile-filter-panel-footer button:first-child:active {
  opacity: 0.8;
}

/* Add these new styles */
.format-toggle-container {
    display: flex;
    align-items: center;
    background-color: #1a1a1a;
    border-radius: 24px;
    padding: 4px;
    gap: 4px;
}

.format-toggle-option {
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    color: #888;
    user-select: none;
}

.format-toggle-option:hover {
    color: #fff;
}

.format-toggle-option.active {
    background-color: #333;
    color: #fff;
}
