.main-header {
  padding: 1rem 0;
}

.main-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
}

.logo {
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 100%;
}

.logo-text {
  text-transform: uppercase;
  font-weight: 800;
  /* color: #2B2628; */
  padding: 6px 12px;
  /* background-color: #4A636D; */
  background-color: #BF2722;
  color: #f7f7f7;
  border-radius: 4px;
}

/* Desktop styles */
.desktop-nav {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.desktop-watchlist-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desktop-watchlist-icon {
  font-size: 24px;
  color: #ffffff;
  transition: color 0.3s ease;
}

.desktop-watchlist-icon:hover {
  color: #ff4081; /* Or any color that fits your theme */
}

.desktop-auth-button {
  background-color: #4A636D;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  font-family: 'SUSE', sans-serif;
  transition: all 0.3s ease;
}

.desktop-auth-button:hover {
  background-color: #395059;
}

.desktop-auth-button.desktop-auth-button-login {
  background-color: transparent;
  color: white;
  text-decoration: none;
}

.desktop-profile-icon {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #BF2722;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
}

.desktop-logout-button {
  background-color: transparent;
  color: white;
  padding: 0;
}

.desktop-logout-button:hover {
  background-color: transparent;
  text-decoration: underline;
}

/* Mobile styles */
.mobile-nav-container {
  display: none;
}

@media (max-width: 768px) {
  .main-header .container {
    padding: 0 1rem;
    position: relative;
    justify-content: center;
  }

  .desktop-nav {
    display: none;
  }

  .mobile-nav-container {
    display: block;
  }

  .mobile-nav {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #C6D3D8;
    display: none;
    padding: 1rem;
    z-index: 5;
  }

  .mobile-nav.open {
    display: block;
  }

  .mobile-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .mobile-nav ul li {
    margin-bottom: 1rem;
  }

  .mobile-auth-button {
    background-color: #4A636D;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    font-family: 'SUSE', sans-serif;
    transition: all 0.3s ease;
    width: 100%;
    text-align: center;
    display: block;
    text-decoration: none;
  }

  .mobile-auth-button:hover {
    background-color: #395059;
  }

  .mobile-auth-button.mobile-auth-button-login {
    background-color: transparent;
    color: #4A636D;
    text-decoration: none;
  }

  .mobile-profile-text {
    font-weight: 500;
  }

  .mobile-hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 1.5rem;
    height: 1.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
  }

  .mobile-hamburger span {
    width: 1.5rem;
    height: 0.2rem;
    background-color: white;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }

  .mobile-hamburger.open span:first-child {
    transform: rotate(45deg);
  }

  .mobile-hamburger.open span:nth-child(2) {
    opacity: 0;
  }

  .mobile-hamburger.open span:nth-child(3) {
    transform: rotate(-45deg);
  }
}

@keyframes watchlistAnimation {
  0% {
    transform: rotate(0deg);
    color: #ffffff;
  }
  50% {
    transform: rotate(30deg);
    color: #BF2722;
  }
  100% {
    transform: rotate(0deg);
    color: #ffffff;
  }
}

.animate-watchlist .desktop-watchlist-icon {
  animation: watchlistAnimation 1s ease-in-out;
}