.login-container {
  max-width: 400px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-container h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #395059;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #395059;
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.error {
  color: #ff0000;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

button[type="submit"] {
  background-color: #395059;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

button[type="submit"]:hover {
  background-color: #2c3e44;
}

button[type="submit"]:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.login-container p {
  text-align: center;
  margin-top: 1rem;
}

.login-container a {
  color: #395059;
  text-decoration: none;
}

.login-container a:hover {
  text-decoration: underline;
}

@media (max-width: 480px) {
  .login-container {
    margin: 1rem;
    padding: 1rem;
  }
}