.user-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 0;
}

.user-list-desktop-view {
  display: block;
}

.user-list-mobile-view {
  display: none;
}

.user-list-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.user-list-empty {
  text-align: center;
  font-style: italic;
  color: #666;
}

.user-list-desktop .user-list-items {
  display: grid;
  gap: 20px;
}

.user-list-desktop .user-list-items.few-items {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.user-list-desktop .user-list-items.many-items {
  grid-template-columns: repeat(5, 1fr);
}

.user-list-desktop .user-list-item {
  position: relative;
  cursor: default;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s ease;
}

.user-list-desktop .user-list-item:hover {
  transform: scale(1.05);
  z-index: 1000; /* Ensure the hovered item is above others */
}

.user-list-desktop .user-list-item-poster {
  width: 100%;
  aspect-ratio: 2 / 3;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-list-desktop .user-list-item-poster-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.user-list-desktop .user-list-item-poster-placeholder {
  width: 100%;
  height: 100%;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: #666;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.user-list-desktop .user-list-item-added {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
  text-align: center;
}

.user-list-mobile .user-list-item-added,
.user-list-mobile .user-list-item-release-date {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.user-list-desktop .user-list-item-hover-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.user-list-desktop .user-list-item-hover-meta .user-list-item-hover-ratings {
  display: flex;
  align-items: center;
  gap: 12px;
}

.user-list-desktop .user-list-item-hover-menu {
  position: absolute;
  top: -10px;
  left: calc(100% - 10px);
  width: 450px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  z-index: 1001;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  opacity: 0;
  visibility: hidden;
  color: var(--color-background-dark);
}

.user-list-desktop .user-list-item-hover-actions,
.user-list-mobile .user-list-item-actions {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 12px;
}

.user-list-desktop .user-list-item:hover .user-list-item-hover-menu {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.2s; /* 0.2s delay before the menu appears */
}

/* Adjust positioning for items near the right edge */
.user-list-desktop .user-list-item:nth-child(5n) .user-list-item-hover-menu,
.user-list-desktop .user-list-item:nth-child(5n-1) .user-list-item-hover-menu {
  left: auto;
  right: calc(100% - 10px); /* Overlap the item slightly */
}

/* Adjust positioning for items near the bottom edge */
.user-list-desktop .user-list-item:nth-last-child(-n+5) .user-list-item-hover-menu {
  top: auto;
  bottom: -10px;
}

.user-list-desktop .user-list-item-hover-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.user-list-desktop .user-list-item-hover-title {
  font-size: 18px;
  margin: 0;
  word-wrap: break-word;
  text-decoration: underline;
  cursor: pointer;
  flex-grow: 1;
  padding-right: 10px;
}

.user-list-desktop .user-list-item-bookmark,
.user-list-desktop .user-list-item-watched {
  font-size: 18px;
  cursor: pointer;
  flex-shrink: 0;
  color: #7ab18e;
}

.user-list-desktop .user-list-item-watched {
  color: #689cd4;
}

.user-list-desktop .user-list-item-bookmark .user-list-item-bookmark-add {
  color: #aaaaaaa5;
}

.user-list-desktop .user-list-item-hover-summary-container {
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 15px;
}

.user-list-desktop .user-list-item-hover-summary {
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
}

.user-list-desktop .user-list-item-hover-buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
}

.user-list-desktop .user-list-item-remove-btn,
.user-list-desktop .user-list-item-see-more-btn {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.user-list-desktop .user-list-item-see-more-btn {
  background-color: #BF2722;
  color: white;
}

.user-list-desktop .user-list-item-see-more-btn:hover {
  background-color: #9d1d19;
}

.user-list-desktop .user-list-item-hover-release-date,
.user-list-desktop .user-list-item-hover-rating {
  font-size: 14px;
  margin: 5px 0;
  color: #666;
}

/* Adjust the layout for the last item in each row */
.user-list-desktop .user-list-item:nth-child(5n) .user-list-item-hover-menu {
  left: auto;
  right: calc(100% + 10px); /* 10px to the left of the item */
}

/* Mobile styles */
@media (max-width: 768px) {
  .user-list-desktop-view {
    display: none;
  }

  .user-list-mobile-view {
    display: block;
  }

  .user-list-container {
    padding: 10px;
  }

  .user-list-title {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .user-list-mobile .user-list-items {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .user-list-mobile .user-list-item {
    position: relative; /* Ensure this is set for absolute positioning */
    display: flex;
    background-color: #f8f8f8;
    border-radius: 8px;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    align-items: stretch;
    height: 135px; /* Increased from 90px (50% taller) */
  }

  .user-list-mobile .user-list-item-poster {
    position: relative; /* Add this to make it a positioning context for absolute elements */
    width: 90px; /* Increased from 60px */
    height: 135px; /* Increased from 90px */
    flex-shrink: 0;
  }

  .user-list-mobile .user-list-item-poster-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .user-list-mobile .user-list-item-poster-placeholder {
    width: 100%;
    height: 100%;
    background-color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
    color: #666;
  }

  .user-list-mobile .user-list-item-info {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    overflow: hidden;
    position: relative;
  }

  .user-list-mobile .user-list-item-title-row {
    display: flex;
    justify-content: flex-start;
  }

  .user-list-mobile .user-list-item-title {
    font-size: 16px;
    margin: 0;
    flex-grow: 1;
    padding-right: 0;
    color: #1a1a1a;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .user-list-mobile .user-list-item-bookmark {
    position: absolute;
    top: 0;
    right: 5px;
    z-index: 10;
    background-color: transparent;
    border-radius: 50%;
    padding: 0;
    font-size: 27px; /* Increased by 50% from 18px */
    cursor: pointer;
    color: #7ab18e; /* #F2C57C with 80% opacity */
  }

  .user-list-mobile .user-list-item-bookmark .user-list-item-bookmark-add {
    color: rgba(242, 197, 124, 0.8); /* #F2C57C with 80% opacity */
  }

  .user-list-mobile .user-list-item-ratings {
    display: flex;
    gap: 10px;
  }

  .user-list-mobile .user-list-item-rating {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    color: #666;
    margin: 0;
  }

  .user-list-mobile .user-list-item-rating svg {
    font-size: 14px;
  }

  .user-list-mobile .user-list-item-poster {
    position: relative;
  }

  .user-list-mobile .user-list-item-watch,
  .user-list-mobile .user-list-item-watched-indicator {
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 10;
    background-color: #47474752;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    min-width: 25px;
    height: 25px;
    border-radius: 16px;
    font-size: 14px;
    cursor: pointer;
  }

  .user-list-mobile .user-list-item-watch {
    /* color: #BF2722; */
  }

  .user-list-mobile .user-list-item-watch svg {
    color: white;
    font-size: 14px;
  }

  .user-list-mobile .user-list-item-watched-indicator {
    color: #689cd4;
    cursor: default;
    background-color: transparent;
    font-size: 18px;
  }

  /* Remove the existing .user-list-mobile .user-list-item-actions styles */
  .user-list-mobile .user-list-item-actions {
    display: none;
  }

  .user-list-items-watched .user-list-item-watch,
  .user-list-items-watched .user-list-item-watched-indicator,
  .user-list-items-watched .user-list-item-bookmark {
    display: none;
  }
}